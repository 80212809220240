<template>
  <Layout tituloPagina="Sistema | Ajustes | Pasarelas de pago">
    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="table-responsive">
            <table class="table project-list-table table-nowrap align-middle table-borderless">
              <thead>
                <tr>
                  <th style="width: 250px;" class="text-center"><strong>Pasarela de pago</strong></th>
                  <th><strong>Disponible para</strong></th>
                  <th style="width: 100px;" class="text-center"><strong>Acción</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="pasarela in pasarelas" :key="pasarela.id">
                  <td class="ps-4 text-center">
                    <img
                      :src="pasarela.imagen"
                      :alt="pasarela.nombre"
                      :title="pasarela.nombre"
                      class="avatar-md"
                    />
                  </td>
                  <td>
                    <div class="avatar-group">
                      <div class="avatar-group-item" v-for="pais,index in pasarela.paices" :key="'pais-'+index">
                        <div class="d-inline-block cursor" :title="pais.nombre">
                          <icon :icon="pais.icono" style="font-size: 34px;"></icon>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <a
                          :href="pasarela.documentacion"
                          target="_blank"
                          title="Documentación ArgusBlack"
                          class="px-3 text-info"
                        >
                          <i class="bx bx-info-circle font-size-24"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <router-link
                          :to="{
                            name: 'edicionPasarelasPago',
                            params: { 
                              id: pasarela.id
                            } 
                          }"
                          :title="'Editar pasarela '+pasarela.nombre"
                          class="px-3 text-primary"
                        >
                          <i class="bx bx-pencil font-size-24"></i>
                        </router-link>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { Icon } from '@iconify/vue'
import Layout from '@/views/layouts/main'
import paypal from "@/assets/img/pasarelas/paypal.png"
import mercadoPago from "@/assets/img/pasarelas/mercado_pago.png"
import PasarelaPagoSrv from '@/services/PasarelaPagoSrv'

export default {
  name: 'PasarelasPago',
  components: { Layout, Icon },
  data() {
    return {
      pasarelas: [],
      paypal: paypal,
      mercadoPago: mercadoPago
    }
  },
  created () {
    var self = this

    self.refrescarPasarelas()
  },
  methods: {
    refrescarPasarelas: function() {
      var self = this

      PasarelaPagoSrv.pasarelasJSON().then(response => {
        let datos = response.data

        datos.forEach(d => {
          switch(d.nombre) {
            case 'PayPal':
              d.imagen = paypal
              d.paices = [
                {nombre: 'México', icono: 'emojione:flag-for-mexico'},
                {nombre: 'Ecuador', icono: 'emojione:flag-for-ecuador'},
                {nombre: 'España', icono: 'circle-flags:es'},
                {nombre: 'Colombia', icono: 'emojione:flag-for-colombia'},
                {nombre: 'Argentina', icono: 'emojione:flag-for-argentina'},
                {nombre: 'Brazil', icono: 'emojione:flag-for-brazil'},
                {nombre: 'Chile', icono: 'emojione:flag-for-chile'},
                {nombre: 'Peru', icono: 'emojione:flag-for-peru'},
                {nombre: 'Guatemala', icono: 'emojione:flag-for-guatemala'},
                {nombre: 'Venezuela', icono: 'emojione:flag-for-venezuela'},
                {nombre: 'Bolivia', icono: 'emojione:flag-for-bolivia'}
              ]
              d.documentacion = 'https://documentacion.argus.black/2023/11/03/4144/'
            break
            case 'Mercado Pago':
              d.imagen = mercadoPago
              d.paices = [
                {nombre: 'México', icono: 'emojione:flag-for-mexico'},
                {nombre: 'Argentina', icono: 'emojione:flag-for-argentina'},
                {nombre: 'Brazil', icono: 'emojione:flag-for-brazil'},
                {nombre: 'Chile', icono: 'emojione:flag-for-chile'},
                {nombre: 'Colombia', icono: 'emojione:flag-for-colombia'},
                {nombre: 'Peru', icono: 'emojione:flag-for-peru'},
                {nombre: 'Uruguay', icono: 'emojione:flag-for-uruguay'}
              ]
              d.documentacion = 'https://documentacion.argus.black/2023/11/09/ver-la-opcion-ayuda-de-mercado-pago/'
            break
          }
        })

        self.pasarelas = datos
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar las pasarelas de pago'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>